<template>
	<div class="page login__page flex column">
		<p class="mt-8 color-white">登录指南，第{{ activeIndex }}步</p>
		<div class="login_guide flex column">
			<div class="share-content">
				<transition name="van-fade" mode="out-in">
					<div class="b share" v-if="activeIndex === 1">分享二维码到附近设备</div>
					<div v-else class="b share text-center">
						<p class="font-size20">用当前手机，<span class="color-primary">面对面</span>扫</p>
						<p class="font-size20"><span class="color-primary">附近</span>设备上的二维码</p>
					</div>
				</transition>
			</div>
			<div class="login_guide__img flex v-top" :style="{transform: activeImgTranslate3d}">
				<img src="./assets/img_guide_1.png" alt="">
				<img src="./assets/img_guide_2.png" alt="">
			</div>
			<div class="login_guide__swipe">
				<i class="login_guide__swipe--active" :style="{transform: activeTranslate3d}"></i>
			</div>
		</div>
		<button class="button login_button" @click="handleNext">下一步</button>
	</div>
</template>

<script>
export default {
	name: 'loginGuide'
}
</script>
<script setup>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const activeIndex = ref(1)
const activeTranslate3d = computed(() => {
	return `translate3d(${(activeIndex.value - 1) * 36}px, 0, 0)`
})

const activeImgTranslate3d = computed(() => {
	return `translate3d(-${(activeIndex.value - 1) * 50}%, 0, 0)`
})
const handleNext = () => {
	if (activeIndex.value === 1) {
		activeIndex.value = 2
	} else {
		router.replace('login')
	}
}
</script>
<style lang="scss" scoped>
.login__page {
	background: linear-gradient(136deg, #FF677C 0%, #FF445E 100%);
}

.login_guide {
	overflow: hidden;
	margin-top: 24px;
	width: 351px;
	height: 444px;
	background: #F6F5F5;
	border-radius: 16px 16px 16px 16px;
}

.share-content {
	height: 120px;
}

.share {
	margin-top: 32px;
	font-size: 20px;
}

.font-size20 {
	font-size: 20px;
}

.login_guide__img {
	overflow: hidden;
	width: 7.02rem;
	align-self: flex-start;
	transition: transform 0.3s;

	img {
		width: 351px;
	}
}

.login_guide__swipe {
	position: relative;
	margin-top: 56px;
	width: 72px;
	height: 6px;
	background: #FFFFFF;
	border-radius: 3px 3px 3px 3px;
}

.login_guide__swipe--active {
	position: absolute;
	display: inline-block;
	width: 36px;
	height: 6px;
	background: #FF445E;
	border-radius: 3px 3px 3px 3px;
	transition: transform 0.3s;
	//transform: translate3d(36px, 0, 0)
}

.login_button {
	margin-top: 32px;
	width: 170px;
	height: 40px;
	font-size: 18px;
	font-family: PingFang SC-Medium, PingFang SC;
	font-weight: 500;
	color: #FFFFFF;
	border-radius: 4px 4px 4px 4px;
	border: 1px solid #FFFFFF;
}
</style>
